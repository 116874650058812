.actionBtnRow {
    text-align: right;
}

.addActionBtn {
    background-color: #1cb0d2 !important;
    /* box-shadow: 0px 1px 7px rgb(204 204 204 / 51%) !important;
    border-radius: 4px !important; */
    font-size: 16px !important;
    line-height: 24px !important;
    text-transform: capitalize !important;
    padding: 9px 25px !important;
    margin-right: 12px !important;
}

.addActionBtn:last-child {
    margin-right: 0px !important;
}

.cal-modal .btn {
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    font-weight: 300;
    font-style: normal;
    font-family: "Poppins";
    width: 245px;
}

.cal-modal .btn-action {
    margin-right: 25px;
}

.cal-modal .btn-action,
.cal-modal .btn-action:hover {
    box-shadow: none;
}

.cal-modal .btn-cancel,
.cal-modal .btn-cancel:hover {
    background: #f1f1f1;
    color: #000;
    box-shadow: none;
}

.cal-modal .modalFooter {
    padding: 0px 45px 55px;
}

@media (max-width: 767px) {
    .cal-modal .btn {
        width: 100%;
        margin: 10px 0;
    }
}